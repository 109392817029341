@font-face {
    font-family: oswald;
    src: url(/public/OFL.txt);
}
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: oswald;
}
:root {
    --gold: #243080;
}

section {
    min-width: 100%;
    z-index: -1;
}
::-webkit-scrollbar {
    width: 7px;

}

::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.1);
}

::-webkit-scrollbar-thumb {
    background: var(--gold);

}
/* --- navbar  ---- */


.navbar {
    height: 60px;
    background: rgba(255, 255, 255, 0.5) !important;
    box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -29px;
    transition: all 0.1s ease-in-out;
    z-index: 5;
}

.navbar2{
    background-color: #fff !important;
    box-shadow:none;
    border-bottom: 1px solid #ededed;
    z-index: 2;
}
.file-input{
    line-height: 3;
}
.navbar:hover {
    background: rgb(255, 255, 255)!important;
}

.nav-container {
    display: flex;
    align-items: center;
    justify-content: space-between;

}
.navbar-nav {
    display: flex;
    align-items: center;
}
.navbar-brand a {
    text-decoration: none;
    text-transform: uppercase;
    font-family: 'Libre Baskerville', serif;
    transition: all 0.3s ease-in-out;
    font-weight: 800;
    letter-spacing: 1px;
    font-style: italic;
    font-size: 28px !important;
    color:#000000;

}

.navbar-brand a:hover {
    color: var(--gold);
}


.navbar-brand:hover span {
    color: #081624;
}


.navbar-links {
    margin-right: 100px;
}

.nav-item {
    font-family: 'Libre Baskerville', serif;
    margin-right: 50px;
}

.nav-link {
    font-size: 18px;
    font-family: 'Libre Baskerville', serif;
    color: #000000 !important;
    transition: all 0.2s ease-in-out;
    margin-right: 4%;
}

.nav-link:hover {
    color: var(--gold)!important;
    text-decoration-line: underline !important;
    text-underline-offset: 8px;
    text-decoration-thickness: 2px !important;
    text-decoration-color: var(--gold);
}
.nav-book{
    background: var(--gold);
    color: #fff;
    border: var(--gold);
    padding: 6px 10px;
    font-size: 15px;
    border-radius: 50px;
   
}
.nav-book:hover{
    background-color: #fff;
    color: var(--gold);
    border: 1px solid var(--gold);
}
.navbar-collapse {
    display: flex;
    justify-content: flex-end;
}


@media (max-width:900px) {

    .navbar-collapse {
        display: flex;
        justify-content: center;
        background: rgba(255, 255, 255, 0.8);

        margin-top: 6px;
    }

    .navbar-brand {
        margin-left: 20px;
    }

    .navbar-nav {
        width: 100%;
        text-align: center;
    }

    .nav-item {
        margin: 0;
    }



    .nav-link {
        width: 100%;
        padding: 21px 0 !important;
    }

    .slide-1 {
        width: 100vw;
        height: 100vh !important;
        object-fit: cover;
    }
}


/* ----nav end---  */

/* carosual  */
.carousel {
   user-select: none;
}
.carousel-control-next, .carousel-control-prev {
    display: none;
}
.caro-img{
    height: 100vh;
    width: 100vw;
    z-index: -1;
}

/* form */
.check-form{
    position: absolute;
    bottom: 0;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    border: 2px solid var(--gold);
    padding: 20px 30px;
    user-select: none;
	background:rgb(255, 255, 255,0.1);
    backdrop-filter: blur(2px);
    display: flex;
    flex-wrap: wrap;
    gap:20px;
    justify-content: center;
    align-items: center;
}


.check-form2{
   margin-top: 20px;
   border: 1px solid rgb(204, 204, 204);
   border-radius: 5px;
    padding: 10px 30px;
    user-select: none;
    display: flex;
    flex-wrap: wrap;
    gap:20px;
    justify-content: space-evenly;
    align-items: center;
}
.check-form-con2{
    display: flex;
    align-items: center;
    justify-content:center;
    flex-wrap: wrap;
padding-bottom: 10px;

}
.check-form-con{
    display: flex;
    align-items: flex-end;
    justify-content: space-around;
    flex-wrap: wrap;
padding: 20px 0 ;

}

  .headerSearchItem {
    display: flex;
    align-items: center;
    gap: 10px;
    
  }
  
  .headerSearchInput {
    border: 1px solid #243080;
  
    outline: none;
  }
  
  .headerSearchText {
    border: 1px solid #243080;
    background:rgb(255, 255, 255,0.6)!important;
   font-weight: 600;
    padding: 10px 20px;
    color: #243080;
    cursor: pointer;
  
   letter-spacing: 2px;
  }
  
  .date {
    position: absolute;
    bottom: 90px;
    left: 0;
    z-index: 2;
     background:rgb(255, 255, 255,0.9)!important;
    backdrop-filter: blur(15px)!important;
  }
    
  .date2 {
    position: absolute;
    top:130px;
    left: 80px;
    z-index: 2;
    border: 1px solid #243080;
    background:rgb(255, 255, 255,0.7)!important;
    backdrop-filter: blur(15px)!important;
  }
  .rdrSelected, .rdrInRange, .rdrStartEdge, .rdrEndEdge {
    background-color:#243080 !important; 
    position: absolute;
    top: 5px;
    left: 0;
    right: 0;
    bottom: 5px;
    outline: none;
}


  .options {
    z-index: 2;
    position: absolute;
    bottom: 90px;
 left:350px;
    color: rgb(0, 0, 0);
    background:rgb(255, 255, 255,0.9)!important;
    backdrop-filter: blur(10px)!important;
   
    -webkit-box-shadow: 0px 0px 10px -5px rgba(0, 0, 0, 0.4);
    box-shadow: 0px 0px 10px -5px rgba(0, 0, 0, 0.4);
  }
  .options2 {
    z-index: 2;
    position: absolute;
    top: 130px;
 left:38%;
    color: rgb(0, 0, 0);
    border: 1px solid #243080;
    background:rgb(255, 255, 255,0.1)!important;
    backdrop-filter: blur(10px)!important;
   
    -webkit-box-shadow: 0px 0px 10px -5px rgba(0, 0, 0, 0.4);
    box-shadow: 0px 0px 10px -5px rgba(0, 0, 0, 0.4);
  }
  .options3 {
    z-index: 2;
    
  

    color: rgb(0, 0, 0);
 
   
   
    -webkit-box-shadow: 0px 0px 10px -5px rgba(0, 0, 0, 0.4);
    box-shadow: 0px 0px 10px -5px rgba(0, 0, 0, 0.4);
  }
  
  .optionItem {
    width: 180px;
    display: flex;
    justify-content: space-between;
    margin: 10px;
  }
  
  .optionCounter {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 15px;
    color: rgb(0, 0, 0);
  }
  .optionCounterNumber {
    width:25px;
    text-align: center;
}
  .optionCounterButton {
    width: 30px;
    font-size: 18px;
    line-height: 30px;
    height: 30px;
    border: 1px solid #243080;
    color: #000000;
    cursor: pointer;
    background:rgb(255, 255, 255,0.1);
    backdrop-filter: blur(15px);
  }
  
  .optionCounterButton:disabled {
    cursor: not-allowed;
  }
  
.date-box {
    display: flex;
    align-items: center;
    
    flex-wrap: wrap;
    
}

.date-select label {
    color: #000000;
    text-transform: uppercase;
    letter-spacing: 2px;
 
    font-size: 13px;
    margin-bottom: 5px;
}

.date-select input {
    font-size: 14px;
    font-weight: 600;
  
    cursor: pointer;
    padding: 0 15px;
    text-transform: uppercase;
    
    border: 1px solid #54B7EA;
    outline: none;
    letter-spacing: 1.2px;
    height: 40px;
    
   
}

.room-quantity {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
   
}

.room-quantity label {
    color: #000000;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: 600;
    font-size: 11px;
    margin-right: 17px;
    margin-bottom: 5px;
   
}

.room-quantity  select {
    height: 40px;
  
 
    line-height: 32px;
    padding: 0px 15px;
    border: 1px solid #243080;
    outline: none;
    cursor: pointer;
    margin-right: 10px;
    outline: none;
}

.check-btn {
   
    padding: 8px 20px;
    font-size: 15px;
    color: #ffffff;
    text-decoration: none   ;
    background: var(--gold);
    letter-spacing: 1px;
    text-transform: uppercase;
    transition: all 0.3s ease-in-out;
    border-radius: 50px;
    border: 1px solid transparent;
    cursor: pointer;

}
.check-btn a {
    color: #ffffff;
    text-decoration: none !important  ;
}
.check-btn:hover a {
    color: var(--gold);

}
.check-btn:hover {
    border: 1px solid var(--gold);
    background-color: rgb(255, 255, 255);
    color: var(--gold);
}

/* ----about ---- */

.about-h1 {
    text-align: center;
    font-weight: 600;
    font-family: 'Libre Baskerville', serif;
    text-transform: uppercase;
    letter-spacing: 2px;
}

.about h2 {
    text-align: center;
    font-weight: 600;
    font-family: 'Libre Baskerville', serif;
}

.about p {
    font-size: 16px;
    padding: 20px 10px;
    text-align: justify;
    line-height: 40px;
    text-transform: capitalize;
}

.btn-about {
    background-color: #fff;
    border: 1px solid var(--gold);
    font-size: 16px;
    padding: 10px 15px;
    text-transform: uppercase;
    transition: all 0.3s ease-in-out;
}

.btn-about:hover {
    background-color: var(--gold);
    color: #fff;
}

.about-img-holder {
    height: 475px;
    overflow: hidden;
}

.about-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: all 0.5s ease-in-out;
}

.about-img:hover {
    transform: scale(1.2);
    border-radius: 5px;
    overflow: hidden;
}

/* -----check room */
.check-img-holder {
    height: 470px;
    overflow: hidden;
    transition: all 0.5s ease-in-out;
   
}

.check-img {
    width: 100%;
    height: 100%;
    z-index: -1;
    object-fit: cover;
    transition: all 0.5s ease-in-out;
    
}

.check-img:hover {
    transform: scale(1.2);
    border-radius: 5px;
    overflow: hidden;
}


/* ----rooms----- */
.room-h1 {
    font-weight: 600;
    font-family: 'Libre Baskerville', serif;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 2px;
}

.room-img-holder {
    height: 250px;
    overflow: hidden;
}

.room-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: all 0.5s ease-in-out;
border-radius: 5px;
overflow: hidden;
}

.room-img:hover {
    transform: scale(1.2);
    overflow: hidden;
    
}
.room-ic{
    color: #243080;
    font-size: 22px;
    margin: 0 11px;
}
.room-name{
    text-transform: uppercase;
    letter-spacing: 1px;
}
.li-card li{
    font-size: 16px;
}
/* ---gallery */



.gallery-h1 {
    font-weight: 600;
    font-family: 'Libre Baskerville', serif;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 2px;
    padding-top: 62px;
}




.gallery {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.gallery-img {
    padding: 5px;
    overflow: hidden;


}

.gal-img {
    width: 400px;
    border-radius: 5px !important;
    transition: all 0.5s ease-in-out;
    touch-action: pan-x pan-y;
}

.gal-img:hover {
   
    transform: scale(1.2);
    overflow: hidden;
}




/* ----contact ----- */
.section-heading {
    font-weight: 600;
    font-family: 'Libre Baskerville', serif;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 2px;
    padding: 10px 0;
}

.form-group label {
    color: var(--gold);
    padding-bottom: 10px;
    margin-left: 10px;
}



.contact-form {
    border: 2px solid var(--gold);
    padding: 25px 30px;
}

.input-control {
    width: 100%;
    padding: 8px;
    outline: none !important;
    border: none;
    border-bottom: 1px solid var(--gold) !important;
}

.input-control-m {
    width: 100%;
    padding: 8px;
    outline: none !important;
    border: none;
    border: 1px solid var(--gold) !important;
}




.contact-btn {
    margin-top: 20px;
    padding: 8px 15px;
    font-size: 15px;
    color: #ffffff;
    background: var(--gold);
    text-transform: uppercase;
    border: 1px solid transparent;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

.contact-btn:hover {
    border: 1px solid var(--gold);
    background-color: rgb(255, 255, 255);
    color: var(--gold);
}
.iframe{
    width: 100%;
    height: 95%;
}
/* ------- footer ------------ */
.footer-title {
    font-family: 'Libre Baskerville', serif;
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 5px;
    margin-left: 27px;
    text-transform: uppercase;
}
.linkk a{
    font-weight: 400;
    font-size: 17px;
}
.linkk a:hover{
    color: #fff;
}
.footer a {
    color: #000000;
    text-decoration: none;
}

.footer a:hover {
    color: #fff !important;
    text-decoration: none;
}


.footer-icons  {
    font-size: 24px;
    cursor: pointer;
}

.footer-icons:hover {
    color: #fff;
}

.footer {
    background-image: url(../public/images/map-image.png);
    background-color: rgba(14, 14, 14, 0.5);
    backdrop-filter: blur(4px);
    padding: 10px 0;
   user-select: #fff;
}

::selection {
   
    color: rgb(0, 0, 0);
    background: rgb(255, 255, 255);
  }
/* 
hotel list */

.hotel-li{
    
    min-height: 100vh;
}
.hl-con{
    display: flex;
   
}
.hl-img-1{
    
    height: 300px;
    object-fit: cover;
    border: 1px solid #fff;
}
.hl-img-2{
   height: 150px;
   width: 50%;
   object-fit: cover;
   border: 1px solid #fff;
}
.li-star{

    color: #ffd700;
}
.li-ul{
    display: flex;
    list-style: none;
    flex-wrap: wrap;

}
.li-ul li{
    width: 25%;
   padding: 30px 40px;

    text-transform: uppercase;
    font-weight: 500;
    
    
}
.li-h2{
    text-transform: uppercase;
    font-weight: 600;
    
}
.li-p{
    margin-left: 30px;
    line-height: 35px;
    text-transform: capitalize;
}
.icon{
    color: var(--gold);
    font-size: 25px;
    margin-right: 20px;
    font-weight: bold;
}
.li-card-b{
    display: flex;
    justify-content: space-between;
}
.card{

    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    transition: all 0.2s ease;
}
.card:hover{
    transform: scale(1.02);
    cursor: pointer;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.li-card{
    list-style: none;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    
}

.card-ti{
  
    text-transform: uppercase;
    font-weight: 600;
}
.li-card li{
    padding: 10px 0;
   
}




.icon2{
    color: var(--gold);
    margin-right: 8px;
}
.card2{
    padding: 10px;
    border: 2px solid #f1f1f1;
}
.form-book{
    box-shadow: none;
    position: sticky;
    top:11% ;
    
}
.form-book:hover{
    transform: none;
    box-shadow: none;
}

.img-2{
    height: 20px;
}
.form-room{
    border: 1px solid rgb(204, 204, 204);
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    border-radius: 5px;
padding: 0 ;

}

.form-img{
    height: 100%;
    width: 100%;
    overflow: hidden;
    object-fit: cover;
    }
    .form-tit{
        text-transform: uppercase;
        letter-spacing: 1.5px;
        padding: 0px 15px;
        font-weight: 500;
    }
    .form-ul{
        display: flex;
        flex-wrap: wrap;
        list-style: none;
        text-align: center;
        justify-content: space-evenly;
        line-height: 3;
        margin-top: 25px;
        
    }
    .form-ul li{
        padding: 0 5px;
        font-size: 14px;
        text-transform: uppercase;
    }
    .form-pri{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .form-date{
        display: flex;
        justify-content: space-between;
    }
    .bill-total{
        text-transform: uppercase;
        font-size: 30px;
        letter-spacing: 1.5px;
    }
    .card-header{
        text-transform: uppercase;
    }
    .list-group-item{
        display: flex;
        justify-content: space-between;
        border: none;
        align-items: center;
        padding: 0;
        margin-top: 28px;
    }
    .bill-p{
        letter-spacing: 2px;
    }
    .center {
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba(0, 0, 0, );
       

      }
      .wave {
        width: 5px;
        height: 80px;
        background: linear-gradient(45deg, #243080, rgb(116, 105, 15));
        margin: 10px;
        animation: wave 1s linear infinite;
        border-radius: 20px;
      }
      .wave:nth-child(2) {
        animation-delay: 0.1s;
      }
      .wave:nth-child(3) {
        animation-delay: 0.2s;
      }
      .wave:nth-child(4) {
        animation-delay: 0.3s;
      }
      .wave:nth-child(5) {
        animation-delay: 0.4s;
      }
      .wave:nth-child(6) {
        animation-delay: 0.5s;
      }
      .wave:nth-child(7) {
        animation-delay: 0.6s;
      }
      .wave:nth-child(8) {
        animation-delay: 0.7s;
      }
      .wave:nth-child(9) {
        animation-delay: 0.8s;
      }
      .wave:nth-child(10) {
        animation-delay: 0.9s;
      }
      
      @keyframes wave {
        0% {
          transform: scale(0);
        }
        50% {
          transform: scale(1);
        }
        100% {
          transform: scale(0);
        }
      }
      .link22{
        font-size: 18px;
        
        text-decoration: none;
   color: #000000;
    }
    .link22:hover{
 text-decoration: underline;
   color: #243080;
    }
      .btn-cnl{
        border: 1px solid #243080;
        letter-spacing: 0.5px;
        font-size: 15px;
        background-color: #ffffff;
      }
      .btn-cnl2{
        background-color: #243080;
        color: #fff;
        letter-spacing: 1px;
        border: 1px solid #243080 !important;
        outline:none;
      }
      .btn-cnl2:hover{
        background-color: #ffffff;
        color: #000000;
      }
    
      .btn-cnl:hover{
        background-color: #243080;
        color: #fff;
      }
    @media only screen and (max-width: 600px) {
     
        
        .date {
            position: absolute;
            bottom: 240px;
            left: -17px;
            z-index: 2;
            
          }
          .options {
            z-index: 2;
            position: absolute;
            bottom: 150px;
       left: 10px;
            color: rgb(0, 0, 0);
           background-color: #fff;
           
            -webkit-box-shadow: 0px 0px 10px -5px rgba(0, 0, 0, 0.4);
            box-shadow: 0px 0px 10px -5px rgba(0, 0, 0, 0.4);
          }
      }
      .book-btn,.print-btn{
       padding: 5px 15px;
        letter-spacing: 1.5px;
        color: #ffffff;
       
        background: var(--gold);
       
        text-transform: uppercase;
       text-align: center;
        border-radius: 50px;
        border: 1px solid transparent;
        cursor: pointer;
    
      }
      .book-btn:hover,.print-btn:hover{
        background-color: #ffffff;
        color: #243080;
        border: 1px solid var(--gold);
      }
     
.con-1{
    display: flex;
    justify-content: space-between;
    padding: 0 2%;
}
.book-btn2{
    padding: 10px 15px;
    letter-spacing: 1.5px;
    color: #ffffff;
    background: var(--gold);
    text-transform: uppercase;
   text-align: center;
    border-radius: 5px;
    border: 1px solid transparent;
    cursor: pointer;
    margin-right: 5%;
    text-decoration: none;
}
.book-btn2:hover{
    color: #fff;
}
.than h3{
   padding-top:  3%;
   padding-left: 2%;
   letter-spacing: 1.3px;
}
.than h3 span{
   font-weight: bold;
   text-transform: uppercase;
   letter-spacing: 1.3px;
 }
.than h5{
    padding:2% 2%;
    letter-spacing: 1.3px;
 }
 .than h5 span{
  font-weight: bold;
    letter-spacing: 1.3px;
 }
 .thank-h5{
    padding: 5% 0;
    letter-spacing: 1.3px;
 }
 .con-2{
    padding: 5% 8% 2% 8%;
 }
 .name{
    text-decoration: none;
    color: var(--gold);
    padding:  0 8%;
    font-size: 22px;
    letter-spacing: 1.3px;
 }
 .name:hover{color: var(--gold);}
 .tha{
    letter-spacing: 1.3px;
    margin: 3% 0;
 }

 .logo{
margin-bottom: 5px;
    width: 17vw;
 }